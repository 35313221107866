import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { createSekoiaTheme } from './theme';

declare module '@mui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={createSekoiaTheme()}>
      <App />
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
