import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useRenameLocation from 'requests/hooks/useRenameLocation';
import { LinearProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCustomerContext } from 'requests/hooks/useCustomerContext';

interface IRenameLocationDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentName: string;
  id: string;
}

export default function RenameLocationDialog(props: IRenameLocationDialogProps) {
  const { open, setOpen, currentName, id } = props;
  const [name, setName] = useState(currentName);
  const organisationMutation = useRenameLocation();
  const customer = useCustomerContext();
  const { enqueueSnackbar } = useSnackbar();

  function renameLocation() {
    if (name !== undefined) {
      organisationMutation.mutate({ id, name, customerId: customer.id });
      setOpen(false);
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Rename Location</DialogTitle>
      <DialogContent style={{ height: 80, width: 200 }}>
        {organisationMutation.isLoading ? (
          <LinearProgress />
        ) : (
          <TextField
            onChange={(event) => setName(event.currentTarget.value)}
            value={name}
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            fullWidth
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            setOpen(false);
            event.stopPropagation();
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={currentName === name || !name}
          onClick={(event) => {
            try {
              event.stopPropagation();
              renameLocation();
            } catch (error) {
              setOpen(false);
              enqueueSnackbar((error as Error).message, {
                variant: 'error',
              });
            }
          }}
          color="primary"
        >
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
}
