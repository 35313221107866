import { UseQueryResult, useQuery } from 'react-query';
import { CustomerStatsClient, CustomerStatsResponse } from 'requests/nswag/atlas/AtlasClient';

async function getAllCustomerStats() {
  const client = new CustomerStatsClient();
  return client.getCustomerStats();
}

export function useFetchCustomerStats(): UseQueryResult<CustomerStatsResponse[]> {
  return useQuery(['CUSTOMERSTATS'], getAllCustomerStats);
}
