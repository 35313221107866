import { useMutation, useQueryClient } from 'react-query';
import { InternalClient, CopyFoldersAndContentRequest } from 'requests/nswag/observations/ObservationsClient';

export default function useCopyFolders() {
  const queryClient = useQueryClient();

  return useMutation(
    async (request: ICopyFolderAndContentRequest) => {
      const client = new InternalClient();
      return await client.copyFolder(
        new CopyFoldersAndContentRequest({
          sourceCustomerId: request.sourceCustomerId,
          destinationCustomerId: request.destinationCustomerId,
          destinationOwnerId: request.destinationOwnerId,
          folderIds: request.folderIds,
        }),
      );
    },
    {
      onSuccess: (_, request) => {
        queryClient.invalidateQueries(['FORM_FOLDERS']);
        queryClient.invalidateQueries(['STANDARD_FORMS', request.sourceCustomerId]);
      },
    },
  );
}

interface ICopyFolderAndContentRequest {
  sourceCustomerId: string;
  destinationCustomerId: string;
  destinationOwnerId: string;
  folderIds: string[];
}
