import React from 'react';
import { Typography, Switch, Divider, Box, Grid, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CustomerToggleResponse, FeatureEnabledForOrganisation } from 'requests/nswag/toggles/TogglesClient';
import { Organisation } from 'requests/nswag/atlas/AtlasClient';

type OrganisationToggleProps = {
  customerToggle: CustomerToggleResponse;
  organisation: Organisation;
  updateCustomerToggle: (updatedToggle: CustomerToggleResponse) => void;
};

const useStyles = makeStyles((theme) => ({
  switchRoot: {
    marginTop: -theme.spacing(1),
    marginLeft: -theme.spacing(1),
  },
}));

function OrganisationToggle({ organisation, customerToggle, updateCustomerToggle }: OrganisationToggleProps) {
  const classes = useStyles();

  function toggleOrganisationToggle(customerToggle: CustomerToggleResponse, organisationId: string) {
    const filteredOrganisations = customerToggle.featureEnabledForOrganisations.filter(
      (toggle) => toggle.organisationId.toLowerCase() !== organisationId.toLowerCase(),
    );

    const isCurrentlyEnabled = customerToggle.featureEnabledForOrganisations.some(
      (toggle) => toggle.organisationId.toLowerCase() === organisationId.toLowerCase() && toggle.isEnabled,
    );

    updateCustomerToggle(
      new CustomerToggleResponse({
        ...customerToggle,
        featureEnabledForOrganisations: [
          ...filteredOrganisations,
          new FeatureEnabledForOrganisation({
            isEnabled: !isCurrentlyEnabled,
            organisationId: organisationId,
          }),
        ],
      }),
    );
  }

  function removeOganisationToggle(customerToggle: CustomerToggleResponse, organisationId: string) {
    const filteredOrganisations = customerToggle.featureEnabledForOrganisations.filter(
      (toggle) => toggle.organisationId.toLowerCase() !== organisationId.toLowerCase(),
    );

    updateCustomerToggle(
      new CustomerToggleResponse({
        ...customerToggle,
        featureEnabledForOrganisations: filteredOrganisations,
      }),
    );
  }

  function renderNotConfigured(customerToggle: CustomerToggleResponse, organisationId: string) {
    return (
      <>
        <Grid item container xs={4} justifyContent="flex-start">
          <Typography>Uses client level toggle</Typography>
        </Grid>

        <Grid item container xs={4} justifyContent="flex-end" style={{ display: 'flex' }}>
          <Button
            onClick={() => {
              toggleOrganisationToggle(customerToggle, organisationId);
            }}
          >
            Configure
          </Button>
        </Grid>
      </>
    );
  }

  function renderConfigured(
    customerToggle: CustomerToggleResponse,
    organisationId: string,
    orgToggle: FeatureEnabledForOrganisation,
  ) {
    return (
      <>
        <Grid item container xs={4} justifyContent="flex-start">
          <Switch
            classes={{ root: classes.switchRoot }}
            checked={orgToggle.isEnabled}
            color={orgToggle.isEnabled ? 'primary' : 'secondary'}
            onClick={() => {
              toggleOrganisationToggle(customerToggle, organisationId);
            }}
          />
        </Grid>
        <Grid item container xs={4} justifyContent="flex-end" style={{ display: 'flex' }}>
          <Button
            onClick={() => {
              removeOganisationToggle(customerToggle, organisationId);
            }}
          >
            Reset to use client level toggle
          </Button>
        </Grid>
      </>
    );
  }

  const orgToggle = customerToggle.featureEnabledForOrganisations.find(
    (toggle) => toggle.organisationId.toLowerCase() === organisation.id.toLowerCase(),
  );

  return (
    <Box key={organisation.id} mb={1} mt={1}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={4}>
          <Typography component="span">{organisation.name}:</Typography>
        </Grid>
        {orgToggle
          ? renderConfigured(customerToggle, organisation.id, orgToggle)
          : renderNotConfigured(customerToggle, organisation.id)}
      </Grid>
      <Divider />
    </Box>
  );
}

export default OrganisationToggle;
