import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CustomerSelect from '../Components/CustomerSelect';
import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { AuthContext } from 'Authentication/AuthProvider';
import { CustomerContext } from './Homepage';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  customerSelect: {
    flexGrow: 1,
    marginLeft: theme.spacing(6),
  },
}));

function TopBar() {
  const classes = useStyles();
  const { customer, setCustomer } = useContext(CustomerContext);
  const { logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
  };

  return (
    <AppBar sx={{ height: 64 }}>
      <Toolbar>
        <Typography variant="subtitle1">Customer configuration</Typography>
        <Box className={classes.customerSelect}>
          <CustomerSelect customer={customer} setCustomer={setCustomer} />
        </Box>
        <Button color="inherit" onClick={handleLogout}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
