import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import { CustomerContext, ExtendedCustomerResponse } from 'Components/Homepage';
import CustomerSelect from 'Components/CustomerSelect';
import LocationsSelector from 'Components/LocationsSelector/LocationsSelector';
import useFetchStandardFormLocations from 'requests/hooks/useFetchStandardFormLocations';
import { Grid, LinearProgress } from '@mui/material';
import useCopyForms from 'requests/hooks/useCopyForms';
import useCopyFolders from 'requests/hooks/useCopyFolders';

interface ICopyDialogProps {
  selectedFormIds: string[];
  selectedFolderIds: string[];
}

export default function CopyDialog(props: ICopyDialogProps) {
  const { selectedFormIds, selectedFolderIds } = props;
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCustomer, setSelectedCustomer] = useState<ExtendedCustomerResponse | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<string[]>([]);
  const locations = useFetchStandardFormLocations(selectedCustomer?.id);
  const customerContext = useContext(CustomerContext);
  const copyFormHook = useCopyForms();
  const copyFolderHook = useCopyFolders();
  const [isLoading, setIsLoading] = useState(false);

  const setCustomer = (customer: ExtendedCustomerResponse) => {
    setSelectedCustomer(customer);
  };

  const handleCopy = () => {
    if (!customerContext.customer || !selectedCustomer || !selectedLocation) return;
    if (selectedFormIds.length > 0) {
      copyFormHook.mutate({
        sourceCustomerId: customerContext.customer?.id,
        destinationCustomerId: selectedCustomer?.id,
        destinationOwnerId: selectedLocation[0],
        formIds: selectedFormIds,
      });
    }
    if (selectedFolderIds.length > 0) {
      copyFolderHook.mutate({
        sourceCustomerId: customerContext.customer.id,
        destinationCustomerId: selectedCustomer.id,
        destinationOwnerId: selectedLocation[0],
        folderIds: selectedFolderIds,
      });
    }
  };

  useEffect(() => {
    setIsLoading(copyFormHook.isLoading || copyFolderHook.isLoading);
  }, [copyFolderHook.isLoading, copyFormHook.isLoading]);

  useEffect(() => {
    if (copyFolderHook.isSuccess) {
      enqueueSnackbar('Successfully copied folders', { variant: 'success' });
    }
  }, [copyFolderHook.isSuccess, enqueueSnackbar]);

  useEffect(() => {
    if (copyFolderHook.isError) {
      enqueueSnackbar('Copy folders: Something is wrong, call IT', { variant: 'error' });
      copyFolderHook.reset();
    }
  }, [copyFolderHook, copyFolderHook.isError, enqueueSnackbar]);

  useEffect(() => {
    if (copyFormHook.isSuccess) {
      enqueueSnackbar('Successfully copied forms', { variant: 'success' });
    }
  }, [copyFormHook.isSuccess, enqueueSnackbar]);

  useEffect(() => {
    if (copyFormHook.isError) {
      enqueueSnackbar('Copy forms: Something is wrong, call IT', { variant: 'error' });
      copyFormHook.reset();
    }
  }, [copyFormHook, copyFormHook.isError, enqueueSnackbar]);

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        color="secondary"
        variant="contained"
        disabled={selectedFormIds.length === 0 && selectedFolderIds.length === 0}
      >
        Copy chosen forms and folders
      </Button>
      <Dialog open={open}>
        <DialogTitle>Copy chosen forms and folders</DialogTitle>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CustomerSelect customer={selectedCustomer} setCustomer={setCustomer} color={'#000'} />
                </Grid>
                <Grid item xs={12}>
                  <LocationsSelector
                    selectedLocationsIds={selectedLocation}
                    onSelectedLocationsChange={setSelectedLocation}
                    locations={locations.data?.locations ?? []}
                    singleSelector
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button color="primary" onClick={handleCopy}>
                Copy
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
