import { useQuery, UseQueryResult } from 'react-query';
import { GetPlatformsList } from 'requests/compassClient';

async function getPlatformsList(compassUrl: string | undefined) {
  return await GetPlatformsList(compassUrl ? compassUrl : '');
}

export function useFetchPlatformsList(customerId: string, compassUrl: string | undefined): UseQueryResult<Platform[]> {
  return useQuery(['PlatFormsList', customerId.toLowerCase()], () => getPlatformsList(compassUrl), {
    enabled: !!compassUrl,
  });
}

export interface Platform {
  addresses: Address[];
  displayName: string;
  id: string;
  karunaConfiguration: KarunaConfiguration;
}

interface Address {
  name: string;
  value: string;
}

interface KarunaConfiguration {
  apartmentId: string;
  apartmentDisplayName: string;
  customerId: string;
  departmentId: string;
  loginLocationId: string;
  locationId: string;
  organisationId: string;
  profiles: UserProfile[];
}

interface UserProfile {
  globalId: string;
  fullName: string;
}
