import { useMutation, useQueryClient } from 'react-query';
import { LocationsInternalClient, CreateLocationRequest } from '../nswag/atlas/AtlasClient';

export default function useCreateApartment() {
  const queryClient = useQueryClient();
  return useMutation(
    async (request: ICreateApartmentRequest) => {
      const client = new LocationsInternalClient();
      return await client.createApartment(request.createLocationRequest);
    },
    {
      onSuccess: (_, request) => {
        queryClient.invalidateQueries(['CUSTOMER', request.customerId]);
      },
    },
  );
}

interface ICreateApartmentRequest {
  createLocationRequest: CreateLocationRequest;
  customerId: string;
}
