import { TableCell, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { StandardFormLocation } from './FormsHelper';
import { useStandardFormContext } from './StandardFormProvider';
import StandardFormsTableFolders from './StandardFormsTableFolders';
import StandardFormsTableForms from './StandardFormsTableForms';

const useStyles = makeStyles((theme) => ({
  customer: {
    backgroundColor: theme.palette.grey[300],
  },
  organisation: {
    backgroundColor: theme.palette.grey[200],
  },
  department: {
    backgroundColor: theme.palette.grey[100],
  },
  apartment: {},
}));

type StandardFormsTableLocationProps = {
  location: StandardFormLocation;
};

function StandardFormLocationRow({ location }: StandardFormsTableLocationProps) {
  const classes = useStyles();
  const { forms, folders } = useStandardFormContext();

  const filteredForms = forms?.filter((form) => form.locationId === location.id);
  const filteredFormsNoFolder = filteredForms?.filter((form) => !form.folderId);
  const filteredFolders = folders?.filter((folder) => folder.ownerId === location.id) ?? [];

  if (!filteredForms || (filteredForms.length < 1 && filteredFolders.length < 1)) return null;

  return (
    <>
      <TableRow>
        <TableCell variant="head" width={60} classes={{ stickyHeader: classes[location.locationType] }} />
        <TableCell
          variant="head"
          component="th"
          scope="row"
          colSpan={6}
          classes={{ stickyHeader: classes[location.locationType] }}
        >
          {location.name} ({filteredForms ? filteredForms.length : 0})
        </TableCell>
      </TableRow>
      <StandardFormsTableFolders locationId={location.id} folders={filteredFolders} />
      <StandardFormsTableForms forms={filteredFormsNoFolder ?? []} />
    </>
  );
}

export default StandardFormLocationRow;
