import { useQuery, UseQueryResult } from 'react-query';
import { GetAllCustomerSettings } from 'requests/authenticationClient';

async function getAllCustomerSettings() {
  return await GetAllCustomerSettings();
}

export function useFetchAllCustomerSettings(): UseQueryResult<CustomerSettings[]> {
  return useQuery(['AllCustomerSettings'], getAllCustomerSettings);
}

interface CustomerSettings {
  compassUrl: string;
  customerLegacyId: number;
}
