import { ToggleResponse, InternalTogglesClient } from '../nswag/toggles/TogglesClient';
import { useQuery, UseQueryResult } from 'react-query';
import CurrentUserInformation from 'Authentication/CurrentUserInformation';

async function getAllToggles() {
  const sekoiaCustomerId = CurrentUserInformation.Instance.getUserInformation().customerId;
  const client = new InternalTogglesClient();

  return await client.getAllToggles(sekoiaCustomerId);
}

export function useFetchAllToggles(): UseQueryResult<ToggleResponse[]> {
  return useQuery('ALL_TOGGLES', () => getAllToggles());
}
