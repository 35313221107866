import { useQuery, UseQueryResult } from 'react-query';
import { Customer, LocationsInternalClient } from '../nswag/atlas/AtlasClient';

async function getCustomerTree(customerId?: string) {
  if (!customerId) return;
  const client = new LocationsInternalClient();
  return client.getCustomer(customerId, false);
}

export function useFetchCustomer(customerId?: string): UseQueryResult<Customer> {
  return useQuery(['CUSTOMER', customerId], () => getCustomerTree(customerId), {
    enabled: !!customerId,
    cacheTime: 15 * 60 * 1000, // 15 minutes,
    staleTime: Infinity,
  });
}
