import { useMutation, useQueryClient } from 'react-query';
import { LocationsInternalClient } from '../nswag/atlas/AtlasClient';

export default function useDeleteApartment() {
  const queryClient = useQueryClient();
  return useMutation(
    async (request: IDeleteApartmentRequest) => {
      const client = new LocationsInternalClient();
      return await client.deleteApartment(request.apartmentId);
    },
    {
      onSuccess: (_, request) => {
        queryClient.invalidateQueries(['CUSTOMER', request.customerId]);
      },
    },
  );
}

interface IDeleteApartmentRequest {
  apartmentId: string;
  customerId: string;
}
