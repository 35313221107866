import { useMutation, useQueryClient } from 'react-query';
import { LocationsInternalClient, RenameLocationRequest } from '../nswag/atlas/AtlasClient';

export default function useRenameLocation() {
  const queryClient = useQueryClient();
  return useMutation(
    async (request: IRenameLocationRequest) => {
      const client = new LocationsInternalClient();
      return await client.renameLocation(request.id, new RenameLocationRequest({ name: request.name }));
    },
    {
      onSuccess: (_, request) => {
        queryClient.invalidateQueries(['CUSTOMER', request.customerId]);
      },
    },
  );
}

interface IRenameLocationRequest {
  id: string;
  name: string;
  customerId: string;
}
