import { useQuery, UseQueryResult } from 'react-query';
import { getKombitCustomerHierarchy } from 'requests/kombitClient';

async function getCustomerHierarchy(cvrNumber: string) {
  return await getKombitCustomerHierarchy(cvrNumber);
}

export function useFetchKombitCustomer(cvrNumber: string): UseQueryResult<KombitTree> {
  return useQuery(
    ['KombitCustomer', cvrNumber],
    () => {
      const response = getCustomerHierarchy(cvrNumber);
      return response;
    },
    { enabled: !!cvrNumber, cacheTime: 15 * 60 * 1000, staleTime: Infinity },
  );
}

export interface KombitLocations {
  Id: string;
  Name: string;
  Children: KombitLocations[];
}

export interface KombitTree {
  CvrNumber: string;
  OrganisationTree: KombitLocations;
}
