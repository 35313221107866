import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RenameLocationDialog from './RenameLocationDialog';
import DeleteLocationDialog from './DeleteLocationDialog';
import useDeleteApartment from 'requests/hooks/useDeleteApartment';
import { useCustomerContext } from 'requests/hooks/useCustomerContext';

type ApartmentDotMenuProps = {
  apartmentId: string;
  apartmentName: string;
};

function ApartmentDotMenu({ apartmentId, apartmentName }: ApartmentDotMenuProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const customer = useCustomerContext();
  const [openRenameApartment, setOpenRenameApartment] = useState(false);
  const [openDeleteApartment, setOpenDeleteApartment] = useState(false);
  const deleteApartmentMutation = useDeleteApartment();

  async function deleteApartment() {
    await deleteApartmentMutation.mutateAsync({
      apartmentId,
      customerId: customer.id,
    });
    setOpenDeleteApartment(false);
  }

  function onMenuClick(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }

  function onMenuClose() {
    setMenuAnchorEl(null);
  }

  const open = Boolean(menuAnchorEl);
  return (
    <>
      <IconButton style={{ padding: 0 }} onClick={onMenuClick} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={open}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            setOpenRenameApartment(true);
            onMenuClose();
          }}
        >
          <Typography>Rename apartment</Typography>
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            setOpenDeleteApartment(true);
            onMenuClose();
          }}
        >
          <Typography>Delete apartment</Typography>
        </MenuItem>
      </Menu>
      <RenameLocationDialog
        currentName={apartmentName}
        setOpen={setOpenRenameApartment}
        open={openRenameApartment}
        id={apartmentId}
      />
      <DeleteLocationDialog
        title={'Delete apartment'}
        open={openDeleteApartment}
        setOpen={setOpenDeleteApartment}
        deleteLocation={deleteApartment}
        isLoading={deleteApartmentMutation.isLoading}
      />
    </>
  );
}

export default ApartmentDotMenu;
