import { useMutation, useQueryClient } from 'react-query';
import { CreateStandardTaskRequest, StandardTasksClient } from 'requests/nswag/tasks/TasksClient';

export default function useCreateStandardTask() {
  const queryClient = useQueryClient();
  return useMutation(
    async (request: ICreateStandardTaskRequest) => {
      const client = new StandardTasksClient();
      return await client.createStandardTask(request.organisationId, request.request);
    },
    {
      onSuccess: (_, request) => {
        queryClient.invalidateQueries(['STANDARD_TASKS', request.organisationId]);
      },
    },
  );
}

interface ICreateStandardTaskRequest {
  organisationId: string;
  request: CreateStandardTaskRequest;
}
