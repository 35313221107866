import makeStyles from '@mui/styles/makeStyles';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Typography, Grid, Button } from '@mui/material';
import React, { useState } from 'react';
import CreateLocationDialog from './CreateLocationDialog';
import { useFetchCustomer } from 'requests/hooks/useFetchCustomer';
import useCreateOrganisation from 'requests/hooks/useCreateOrganisation';
import { Apartment, CreateLocationRequest, Department, Organisation } from 'requests/nswag/atlas/AtlasClient';
import OrganisationDotMenu from 'Components/OrganisationDotMenu';
import DepartmentDotMenu from 'Components/DepartmentDotMenu';
import ApartmentDotMenu from 'Components/ApartmentDotMenu';
import { useCustomerContext } from 'requests/hooks/useCustomerContext';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  treeItem: {
    '& .MuiTreeItem-group': {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed black`,
    },
    '& .MuiTreeItem-label': {
      fontSize: 16,
    },
  },
  treeItemTitle: {
    padding: 8,
  },
});

export default function Locations() {
  const classes = useStyles();
  const customer = useCustomerContext();
  const { data } = useFetchCustomer(customer.id);
  const [openCreateOrganisation, setOpenCreateOrganisation] = useState(false);
  const createOrganisationMutation = useCreateOrganisation();
  const [parentId, setParentId] = useState<string>('');

  function createOrganisation(name: string | undefined) {
    if (name !== undefined) {
      createOrganisationMutation.mutate(new CreateLocationRequest({ parentId, name }));
      setOpenCreateOrganisation(false);
    }
  }

  if (data == null) return null;

  function renderOrganisations(organisations: Organisation[]) {
    return organisations.map((org) => {
      return (
        <TreeItem
          key={org.id}
          nodeId={org.id}
          label={
            <Grid container direction="row" justifyContent="space-between">
              <Typography className={classes.treeItemTitle}>{org.name}</Typography>
              <OrganisationDotMenu organisationId={org.id} organisationName={org.name} />
            </Grid>
          }
          className={classes.treeItem}
        >
          {renderDepartments(org.departments)}
        </TreeItem>
      );
    });
  }

  function renderDepartments(departments: Department[]) {
    return departments.map((dep) => {
      return (
        <TreeItem
          key={dep.id}
          nodeId={dep.id}
          label={
            <Grid container direction="row" justifyContent="space-between">
              <Typography className={classes.treeItemTitle}>{dep.name}</Typography>
              <DepartmentDotMenu departmentId={dep.id} departmentName={dep.name} />
            </Grid>
          }
          className={classes.treeItem}
        >
          {renderApartments(dep.apartments)}
        </TreeItem>
      );
    });
  }

  function renderApartments(apartments: Apartment[]) {
    return apartments.map((apt) => {
      return (
        <TreeItem
          key={apt.id}
          nodeId={apt.id}
          label={
            <Grid container direction="row" justifyContent="space-between">
              <Typography className={classes.treeItemTitle}>{apt.name}</Typography>
              <ApartmentDotMenu apartmentId={apt.id} apartmentName={apt.name} />
            </Grid>
          }
          className={classes.treeItem}
        />
      );
    });
  }

  return (
    <Box m={6}>
      <Grid container direction="row" justifyContent="space-between">
        <Typography style={{ fontSize: 32 }}>{customer.name}</Typography>
        <Button
          onClick={() => {
            setParentId(customer.id);
            setOpenCreateOrganisation(true);
          }}
        >
          Add organisation
        </Button>
      </Grid>
      <Box mb={2}>
        <TreeView
          className={classes.root}
          disableSelection
          defaultCollapseIcon={<ExpandLessIcon />}
          defaultExpandIcon={<ExpandMoreIcon />}
        >
          {renderOrganisations(data.organisations)}
        </TreeView>
      </Box>
      <CreateLocationDialog
        title={'Create Orginasation'}
        open={openCreateOrganisation}
        setOpen={setOpenCreateOrganisation}
        createLocation={createOrganisation}
        isLoading={createOrganisationMutation.isLoading}
      />
    </Box>
  );
}
