import { ToggleResponse, CustomerTogglesClient } from '../nswag/toggles/TogglesClient';
import { useQuery, UseQueryResult } from 'react-query';
import CurrentUserInformation from 'Authentication/CurrentUserInformation';

async function getCustomerToggles(customerId: string) {
  const client = new CustomerTogglesClient();

  return client.getCustomerToggles(customerId, CurrentUserInformation.Instance.getUserInformation().customerId);
}

export function useFetchTogglesForCustomer(customerId: string): UseQueryResult<ToggleResponse[]> {
  return useQuery(['TOGGLES', customerId.toLowerCase()], () => getCustomerToggles(customerId));
}
