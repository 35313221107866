/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { colors, createTheme, ThemeOptions } from '@mui/material';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    dark?: string;
  }
}

const sekoiaTheme: ThemeOptions = {
  direction: 'ltr',
  palette: {
    mode: 'light',
    action: {
      active: colors.blueGrey[600],
    },
    background: {
      default: colors.common.white,
      dark: '#f4f6f8',
      paper: colors.common.white,
    },
    primary: {
      main: '#24955e',
    },
    secondary: {
      main: '#5C6D78',
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
    error: {
      main: colors.red[700],
    },
  },
};

export function createSekoiaTheme() {
  const theme = createTheme(sekoiaTheme);
  return theme;
}
