import { useMutation, useQueryClient } from 'react-query';
import { LocationsInternalClient, CreateLocationRequest } from '../nswag/atlas/AtlasClient';

export default function useCreateOrganisation() {
  const queryClient = useQueryClient();
  return useMutation(
    async (request: CreateLocationRequest) => {
      const client = new LocationsInternalClient();
      return await client.createOrganisation(request);
    },
    {
      onSuccess: (_, request) => {
        queryClient.invalidateQueries(['CUSTOMER', request.parentId]);
      },
    },
  );
}
