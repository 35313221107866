import { useMutation, useQueryClient } from 'react-query';
import CurrentUserInformation from '../../Authentication/CurrentUserInformation';
import { InternalTogglesClient, ToggleCustomerFeatureRequest } from '../nswag/toggles/TogglesClient';

async function updateCustomerToggle(request: ToggleCustomerFeatureRequest) {
  const client = new InternalTogglesClient();
  const customerId = CurrentUserInformation.Instance.getUserInformation().customerId;
  return await client.toggleCustomerFeature(request, request.customerId, request.toggleId, customerId);
}

export default function useUpdateCustomerToggle() {
  const queryClient = useQueryClient();

  return useMutation(updateCustomerToggle, {
    onSuccess: (_, request) => {
      queryClient.invalidateQueries(['TOGGLES', request.customerId.toLowerCase()]);
    },
  });
}
