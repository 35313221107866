import React, { useContext, useMemo } from 'react';
import { FolderResponseWithChecked } from 'requests/hooks/useFetchFormsFolders';
import { FormAssignmentResponseWithNameAndLocationType } from 'requests/hooks/useFetchStandardFormLocations';
import { StandardFormLocation } from './FormsHelper';

type StandardFormProviderProps = {
  forms: FormAssignmentResponseWithNameAndLocationType[] | undefined;
  locations: StandardFormLocation[];
  folders: FolderResponseWithChecked[];
  selectedLocationsIds: string[];
  selectedFolderIds: string[];
  setSelectedForm: (id: string) => void;
  setSelectedFolder: (id: string) => void;
};

interface StandardFormContext {
  forms: FormAssignmentResponseWithNameAndLocationType[] | undefined;
  locations: StandardFormLocation[];
  folders: FolderResponseWithChecked[];
  selectedLocations: StandardFormLocation[];
  selectedFolderIds: string[];
  setSelectedForm: (id: string) => void;
  setSelectedFolder: (id: string) => void;
}

export const StandardFormContext = React.createContext<StandardFormContext | null>(null);
export const useStandardFormContext = () => {
  const standardFormContext = useContext(StandardFormContext);

  if (!standardFormContext) {
    throw Error(`No StandardFormContext found!`);
  }
  return standardFormContext;
};

export const StandardFormProvider: React.FC<StandardFormProviderProps> = (props) => {
  const {
    forms,
    locations,
    folders,
    selectedLocationsIds,
    children,
    selectedFolderIds,
    setSelectedForm,
    setSelectedFolder,
  } = props;
  const filteredLocations = useMemo(() => {
    return locations.filter((location) => selectedLocationsIds.includes(location.id));
  }, [locations, selectedLocationsIds]);

  return (
    <StandardFormContext.Provider
      value={{
        forms: forms,
        folders: folders,
        locations: locations,
        selectedLocations: filteredLocations,
        selectedFolderIds: selectedFolderIds,
        setSelectedForm: setSelectedForm,
        setSelectedFolder: setSelectedFolder,
      }}
    >
      {children}
    </StandardFormContext.Provider>
  );
};
