import { IFolderResponse, InternalClient } from 'requests/nswag/observations/ObservationsClient';
import { useQuery, UseQueryResult } from 'react-query';

export interface FolderResponseWithChecked extends IFolderResponse {
  isChecked?: boolean;
}

async function getFormFolders(customerId: string) {
  const internalClient = new InternalClient();
  return await internalClient.getFoldersForCustomer(customerId);
}

function useFetchFormsFolders(customerId: string): UseQueryResult<FolderResponseWithChecked[]> {
  return useQuery(['FORM_FOLDERS', customerId], () => getFormFolders(customerId));
}

export default useFetchFormsFolders;
