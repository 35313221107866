import { IFormResponse } from 'requests/nswag/observations/ObservationsClient';
import { useQuery, UseQueryResult } from 'react-query';
import { useFetchCustomer } from './useFetchCustomer';
import { useCallback, useMemo } from 'react';
import FormsHelper, { StandardFormLocation } from 'Components/StandardForms/FormsHelper';

export enum LocationTypes {
  customer = 'customer',
  organisation = 'organisation',
  department = 'department',
  apartment = 'apartment',
}

export interface FormAssignmentResponseWithNameAndLocationType extends IFormResponse {
  locationName: string | undefined;
  locationType: LocationTypes | undefined;
  locationId: string | undefined;
  isChecked?: boolean;
}

export type LocationType = {
  locations: StandardFormLocation[];
  locationIds: string[];
};

function useFetchStandardFormLocations(customerId?: string): UseQueryResult<LocationType> {
  const { data } = useFetchCustomer(customerId);
  const formsHelper = useMemo(() => new FormsHelper(), []);

  const getStandardFormLocations = useCallback(() => {
    const locations = formsHelper.getStandardFormLocations(data);
    return locations;
  }, [data, formsHelper]);

  return useQuery(['STANDARD_FORM_LOCATIONS', customerId], getStandardFormLocations, {
    enabled: !!data && !!customerId,
  });
}

export default useFetchStandardFormLocations;
