import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateLocationDialog from './CreateLocationDialog';
import RenameLocationDialog from './RenameLocationDialog';
import DeleteLocationDialog from './DeleteLocationDialog';
import useCreateDepartment from 'requests/hooks/useCreateDepartment';
import useDeleteOrganisation from 'requests/hooks/useDeleteOrganisation';
import { CreateLocationRequest } from 'requests/nswag/atlas/AtlasClient';
import { useCustomerContext } from 'requests/hooks/useCustomerContext';

type OrganisationDotMenuProps = {
  organisationId: string;
  organisationName: string;
};

function OrganisationDotMenu({ organisationId, organisationName }: OrganisationDotMenuProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const customer = useCustomerContext();
  const [openCreateDepartment, setOpenCreateDepartment] = useState(false);
  const [openRenameOrganisation, setOpenRenameOrganisation] = useState(false);
  const [openDeleteOrganisation, setOpenDeleteOrganisation] = useState(false);
  const createDepartmentMutation = useCreateDepartment();
  const deleteOrganisationMutation = useDeleteOrganisation();

  function createDepartment(name: string | undefined) {
    if (name !== undefined) {
      createDepartmentMutation.mutate({
        createLocationRequest: new CreateLocationRequest({
          parentId: organisationId,
          name,
        }),
        customerId: customer.id,
      });
      setOpenCreateDepartment(false);
    }
  }

  async function deleteOrganisation() {
    await deleteOrganisationMutation.mutateAsync({
      organisationId,
      customerId: customer.id,
    });
    setOpenDeleteOrganisation(false);
  }

  function onMenuClick(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }

  function onMenuClose() {
    setMenuAnchorEl(null);
  }

  const open = Boolean(menuAnchorEl);
  return (
    <>
      <IconButton style={{ padding: 0 }} onClick={onMenuClick} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={open}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            setOpenCreateDepartment(true);
            onMenuClose();
          }}
        >
          <Typography>Create department</Typography>
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            setOpenRenameOrganisation(true);
            onMenuClose();
          }}
        >
          <Typography>Rename organisation</Typography>
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            setOpenDeleteOrganisation(true);
            onMenuClose();
          }}
        >
          <Typography>Delete organisation</Typography>
        </MenuItem>
      </Menu>
      <CreateLocationDialog
        title={'Create department'}
        open={openCreateDepartment}
        setOpen={setOpenCreateDepartment}
        createLocation={createDepartment}
        isLoading={createDepartmentMutation.isLoading}
      />
      <RenameLocationDialog
        currentName={organisationName}
        setOpen={setOpenRenameOrganisation}
        open={openRenameOrganisation}
        id={organisationId}
      />
      <DeleteLocationDialog
        title={'Delete organisation'}
        open={openDeleteOrganisation}
        setOpen={setOpenDeleteOrganisation}
        deleteLocation={deleteOrganisation}
        isLoading={deleteOrganisationMutation.isLoading}
      />
    </>
  );
}

export default OrganisationDotMenu;
