import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LinearProgress, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

interface IDeleteLoctionDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  deleteLocation: () => void;
  isLoading: boolean;
}

export default function DeleteLocationDialog(props: IDeleteLoctionDialogProps) {
  const { open, setOpen, title, deleteLocation, isLoading } = props;
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {isLoading ? <LinearProgress /> : <Typography>Are you sure you want to delete this location?</Typography>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            setOpen(false);
            event.stopPropagation();
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={async (event) => {
            try {
              event.stopPropagation();
              await deleteLocation();
            } catch (error) {
              setOpen(false);
              enqueueSnackbar((error as Error).message, {
                variant: 'error',
              });
            }
          }}
          style={{ color: '#d32f2f' }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
