import { Checkbox, Collapse, IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useMemo } from 'react';
import { useStandardFormContext } from './StandardFormProvider';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FolderIcon from '@mui/icons-material/Folder';
import StandardFormsTableFormsFormRow from './StandardFormsTableFormsFormRow';
import { FolderResponseWithChecked } from 'requests/hooks/useFetchFormsFolders';

const useStyles = makeStyles((theme) => ({
  icon: {
    position: 'relative',
    top: 4,
    marginRight: theme.spacing(1),
  },
}));

type StandardFormsTableFormsFormRowProps = {
  folder: FolderResponseWithChecked;
};

function StandardFormsTableFormsFolderRow({ folder }: StandardFormsTableFormsFormRowProps) {
  const classes = useStyles();
  const { forms, setSelectedFolder, setSelectedForm } = useStandardFormContext();
  const [open, setOpen] = React.useState(false);

  const filteredForms = useMemo(
    () => forms?.filter((form) => form.folderId === folder.id).sort((a, b) => a.title.localeCompare(b.title)) ?? [],
    [folder.id, forms],
  );

  const handleOpenToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleFolderRowClick = () => {
    const chosenFormsInFolder = filteredForms.filter((form) => form.isChecked);
    if (chosenFormsInFolder.length > 0) {
      chosenFormsInFolder.map((form) => setSelectedForm(form.id));
    }
    setSelectedFolder(folder.id);
  };
  return (
    <>
      <TableRow style={{ cursor: filteredForms.length ? 'pointer' : 'default' }} hover>
        <TableCell onClick={handleOpenToggle}>
          {filteredForms.length > 0 && (
            <IconButton size="small">{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row" colSpan={2} onClick={handleOpenToggle}>
          <FolderIcon fontSize={'small'} className={classes.icon} />
          {folder.name} - ({filteredForms.length})
        </TableCell>
        <TableCell onClick={handleFolderRowClick} align="center">
          <Checkbox checked={!!folder.isChecked} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0, borderBottom: 'none' }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table>
              <TableBody>
                {filteredForms.map((form) => (
                  <StandardFormsTableFormsFormRow form={form} key={form.id} indent />
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default StandardFormsTableFormsFolderRow;
