import { Box, Typography, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useFetchTogglesForCustomer } from '../requests/hooks/useFetchTogglesForCustomer';
import { useFetchCustomer } from '../requests/hooks/useFetchCustomer';
import { useFetchNexusConfiguration } from 'requests/hooks/useFetchNexusConfiguration';
import {
  CustomerToggleResponse,
  ToggleCustomerFeatureRequest,
  ToggleOrganisationFeatureRequest,
  ToggleResponse,
} from 'requests/nswag/toggles/TogglesClient';
import useUpdateCustomerToggle from '../requests/hooks/useUpdateCustomerToggle';
import { sortArrayOfObjects } from '../utils/sortHelpers';
import React, { useMemo } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Toggle from './Toggle';
import { useFetchAllToggles } from 'requests/hooks/useFetchAllToggles';
import { useSnackbar } from 'notistack';
import LoadingScreen from './LoadingScreen';
import { useCustomerContext } from 'requests/hooks/useCustomerContext';

const useStyles = makeStyles((theme) => ({
  togglesHeader: {
    marginBottom: theme.spacing(4),
  },
  infoIcon: { float: 'right', marginTop: 16 },
}));

const tooltipText = `Toggles work the following way:
If a toggle is enabled on the customer level, it is enabled for all organisations within that customer that either have the toggle ENABLED or NOT CONFIGURED.
In the same way, if a toggle is disabled on the customer level, it is disabled for all organisations within the customer that have the toggle DISABLED or NOT CONFIGURED.
`;

function TogglesView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const customer = useCustomerContext();

  const { data: customerLocation } = useFetchCustomer(customer.id);
  const { data: toggles } = useFetchAllToggles();
  const { data: customerToggles } = useFetchTogglesForCustomer(customer.id);
  const { data: nexusConfiguration, status: nexusConfigurationStatus } = useFetchNexusConfiguration(customer.id);
  const customerToggleMutation = useUpdateCustomerToggle();

  const customerTogglesMap = useMemo(() => {
    if (!customerToggles) return new Map();

    return new Map(customerToggles.map((t) => [t.toggleId, t]));
  }, [customerToggles]);

  const sortedToggles = useMemo(() => sortArrayOfObjects(toggles ?? [], 'name'), [toggles]);

  async function updateCustomerToggle(updatedToggle: CustomerToggleResponse) {
    const organisationToggles = updatedToggle.featureEnabledForOrganisations.map(
      (orgToggle) =>
        new ToggleOrganisationFeatureRequest({
          customerId: updatedToggle.customerId,
          organisationId: orgToggle.organisationId.toLowerCase(),
          isEnabled: orgToggle.isEnabled,
        }),
    );

    const request = new ToggleCustomerFeatureRequest({
      customerId: updatedToggle.customerId,
      toggleId: updatedToggle.toggleId,
      isEnabled: updatedToggle.isEnabledForCustomer,
      organisationToggles: organisationToggles,
    });

    try {
      await customerToggleMutation.mutateAsync(request);
    } catch (error) {
      enqueueSnackbar('Something went wrong trying to update toggles', {
        variant: 'error',
      });
    }
  }

  if (!customerLocation || !toggles || !customerToggles || nexusConfigurationStatus !== 'success')
    return <LoadingScreen />;

  const renderToggle = (toggle: ToggleResponse) => {
    const customerToggle = customerTogglesMap.get(toggle.toggleId);

    return (
      <Toggle
        key={toggle.toggleId}
        friendlyName={toggle.friendlyName}
        toggle={customerToggle ?? toggle}
        customerLocation={customerLocation}
        updateCustomerToggle={updateCustomerToggle}
        showWarningIfNexusToggle={!nexusConfiguration}
      />
    );
  };

  return (
    <Box
      m={6}
      mb={2}
      style={{
        maxWidth: '80%',
      }}
    >
      <div className={classes.togglesHeader}>
        <Typography variant="h3" component="span">
          Toggles
        </Typography>
        <Tooltip title={tooltipText}>
          <InfoIcon fontSize="large" className={classes.infoIcon} />
        </Tooltip>
      </div>
      {sortedToggles.map((toggle) => renderToggle(toggle))}
    </Box>
  );
}

export default TogglesView;
