import React from 'react';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import useFetchStandardTasks from 'requests/hooks/useFetchStandardTasks';
import { sortArrayOfObjects } from 'utils/sortHelpers';

type StandardTasksTargetProps = {
  organisationId: string;
};

const StandardTasksTarget: React.FC<StandardTasksTargetProps> = (props) => {
  const { organisationId } = props;
  const { data: standardTasks, status } = useFetchStandardTasks(organisationId);

  if (status === 'loading') return null;

  return (
    <Box m={2}>
      <Box height={68} />
      <List sx={{ width: '100%' }}>
        {sortArrayOfObjects(standardTasks ?? [], 'title').map((task) => {
          return (
            <ListItem key={task.id} disablePadding>
              <ListItemText primary={task.title} />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default StandardTasksTarget;
