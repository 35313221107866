import { Avatar, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AccountBalance, Domain, Hotel, People } from '@mui/icons-material';
import React from 'react';

export enum LocationTypes {
  customer = 'customer',
  organisation = 'organisation',
  department = 'department',
  apartment = 'apartment',
}

type RenderLocationIconTypes = {
  type: LocationTypes | undefined;
  size?: 'small' | 'medium';
};

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    '& > .MuiSvgIcon-fontSizeSmall': {
      fontSize: 14,
    },
  },
}));

export default function RenderLocationIcon({ type, size = 'medium' }: RenderLocationIconTypes) {
  const theme = useTheme();
  const classes = useStyles();

  if (!type) return null;
  return (
    <Avatar
      style={{ backgroundColor: theme.palette.primary.main }}
      className={size !== 'medium' ? classes[size] : undefined}
    >
      {type === LocationTypes.customer && <AccountBalance fontSize={size} />}
      {type === LocationTypes.organisation && <Domain fontSize={size} />}
      {type === LocationTypes.department && <People fontSize={size} />}
      {type === LocationTypes.apartment && <Hotel fontSize={size} />}
    </Avatar>
  );
}
