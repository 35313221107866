import { Typography, Switch, Card, CardContent, CardHeader, Divider, Tooltip, Grid, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CustomerToggleResponse, ToggleResponse } from 'requests/nswag/toggles/TogglesClient';
import { Customer } from 'requests/nswag/atlas/AtlasClient';
import WarningIcon from '@mui/icons-material/Warning';
import { sortArrayOfObjects } from '../utils/sortHelpers';
import React, { useMemo } from 'react';
import OrganisationToggle from 'Components/OrganisationToggle';

type ToggleProps = {
  toggle: ToggleResponse | CustomerToggleResponse;
  friendlyName: string | null | undefined;
  customerLocation: Customer;
  showWarningIfNexusToggle: boolean;
  updateCustomerToggle: (updatedToggle: CustomerToggleResponse) => void;
};

const useStyles = makeStyles((theme) => ({
  toggleContainer: {
    marginBottom: 8,
  },
  cardHeaderRoot: {
    overflow: 'hidden',
  },
  cardHeaderContent: {
    overflow: 'hidden',
  },
  warningIcon: {
    marginLeft: theme.spacing(1),
  },
}));

function Toggle({
  toggle,
  friendlyName,
  customerLocation,
  showWarningIfNexusToggle,
  updateCustomerToggle,
}: ToggleProps) {
  const { name } = toggle;
  const classes = useStyles();

  const sortedOrganisations = useMemo(
    () => sortArrayOfObjects(customerLocation.organisations, 'name'),
    [customerLocation.organisations],
  );

  function toggleCustomerToggle(customerToggle: CustomerToggleResponse) {
    updateCustomerToggle(
      new CustomerToggleResponse({
        ...customerToggle,
      }),
    );
  }

  function renderHeader() {
    return (
      <>
        <Grid container direction="row" justifyContent="space-between" style={{ paddingBottom: 8 }}>
          <Grid item container xs={4}>
            <Typography variant="h6">Organisation name</Typography>
          </Grid>
          <Grid item container xs={4} justifyContent="flex-start" style={{ display: 'flex' }}>
            <Typography variant="h6">Enabled/disabled</Typography>
          </Grid>
          <Grid item xs={4} />
        </Grid>
        <Divider />
      </>
    );
  }

  function renderCardAction() {
    if (toggle instanceof CustomerToggleResponse)
      return (
        <Grid style={{ display: 'flex' }}>
          <Typography style={{ paddingTop: 8 }}>CLIENT LEVEL</Typography>
          <Switch
            color={toggle.isEnabledForCustomer ? 'primary' : 'secondary'}
            checked={toggle.isEnabledForCustomer}
            onClick={() => {
              toggleCustomerToggle(
                new CustomerToggleResponse({
                  ...toggle,
                  isEnabledForCustomer: !toggle.isEnabledForCustomer,
                }),
              );
            }}
          />
        </Grid>
      );

    return (
      <Button
        onClick={() => {
          toggleCustomerToggle(
            new CustomerToggleResponse({
              ...toggle,
              customerId: customerLocation.id.toLowerCase(),
              featureEnabledForOrganisations: [],
              isEnabledForCustomer: true,
            }),
          );
        }}
      >
        Activate this toggle
      </Button>
    );
  }

  function renderNexusNotConfiguredWarning() {
    if (!showWarningIfNexusToggle || !name.toLowerCase().includes('nexus')) return null;

    return (
      <Tooltip title="WARNING: Nexus is not configured for this customer">
        <WarningIcon className={classes.warningIcon} />
      </Tooltip>
    );
  }

  return (
    <Card className={classes.toggleContainer} key={toggle.toggleId}>
      <CardHeader
        classes={{
          root: classes.cardHeaderRoot,
          content: classes.cardHeaderContent,
        }}
        title={
          <>
            <Typography variant="h5" component="span">
              {friendlyName ?? name}
            </Typography>
            {renderNexusNotConfiguredWarning()}
          </>
        }
        subheader={<Typography style={{ marginTop: 12 }}>{name}</Typography>}
        action={renderCardAction()}
      />
      <CardContent>
        {toggle instanceof CustomerToggleResponse && (
          <>
            {renderHeader()}
            {sortedOrganisations.map((org) => (
              <OrganisationToggle
                key={org.id}
                organisation={org}
                customerToggle={toggle}
                updateCustomerToggle={updateCustomerToggle}
              />
            ))}
          </>
        )}
      </CardContent>
    </Card>
  );
}

export default Toggle;
