import React from 'react';
import { Box, Drawer, List, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  desktopDrawer: {
    width: 200,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  button: {
    justifyContent: 'flex-start',
    letterSpacing: 0,
    fontSize: 16,
    width: '100%',
    paddingLeft: 24,
  },
  active: {
    color: theme.palette.primary.main,
  },
}));

function NavBar() {
  const classes = useStyles();

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <List>
        <MenuItem activeClassName={classes.active} component={RouterLink} to={'/locations'} className={classes.button}>
          Locations
        </MenuItem>
        <MenuItem activeClassName={classes.active} component={RouterLink} to={'/toggles'} className={classes.button}>
          Toggles
        </MenuItem>
        <MenuItem activeClassName={classes.active} component={RouterLink} to={'/platforms'} className={classes.button}>
          Platforms
        </MenuItem>
        <MenuItem
          activeClassName={classes.active}
          component={RouterLink}
          to={'/standardforms'}
          className={classes.button}
        >
          Standard forms
        </MenuItem>
        <MenuItem activeClassName={classes.active} component={RouterLink} to={'/kombit'} className={classes.button}>
          Kombit
        </MenuItem>
        <MenuItem
          activeClassName={classes.active}
          component={RouterLink}
          to={'/standardtasks'}
          className={classes.button}
        >
          Standard Tasks
        </MenuItem>
        <MenuItem
          activeClassName={classes.active}
          component={RouterLink}
          to={'/customercount'}
          className={classes.button}
        >
          User Counts
        </MenuItem>
      </List>
    </Box>
  );

  return (
    <Box className="no-print">
      <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
        {content}
      </Drawer>
    </Box>
  );
}

export default NavBar;
