import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import TopBar from './TopBar';
import NavBar from './NavBar';
import NotSekoiaAdminErrorView from './NotSekoiaAdminErrorView';
import { AuthContext } from '../Authentication/AuthProvider';
import { usePrevious } from 'usePrevious';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 200,
    },
  },
  '@media print': {
    wrapper: {
      display: 'unset',
      paddingTop: 'unset',
      flex: 'unset',
      paddingLeft: 'unset',
      width: 'inherit',
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'hidden',
  },
}));

export interface ExtendedCustomerResponse {
  name: string;
  id: string;
  compassUrl: string | undefined;
}
export interface ICustomerContext {
  customer: ExtendedCustomerResponse | null;
  setCustomer: React.Dispatch<React.SetStateAction<ExtendedCustomerResponse | null>>;
}

export const CustomerContext = React.createContext<ICustomerContext>({
  customer: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCustomer: () => {},
});

const Homepage: React.FC = (props) => {
  const classes = useStyles();
  const { isAuthenticated, login } = useContext(AuthContext);
  const prevIsAuthenticated = usePrevious(isAuthenticated);
  const [customer, setCustomer] = useState<ExtendedCustomerResponse | null>(null);

  const customerContextValue = useMemo(
    () => ({
      customer,
      setCustomer,
    }),
    [customer, setCustomer],
  );

  useEffect(() => {
    if (!isAuthenticated && !prevIsAuthenticated) login();
  }, [isAuthenticated, login, prevIsAuthenticated]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className={classes.root}>
      <CustomerContext.Provider value={customerContextValue}>
        <TopBar />
        <NotSekoiaAdminErrorView>
          <NavBar />
          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              <div className={classes.content}>{props.children}</div>
            </div>
          </div>
        </NotSekoiaAdminErrorView>
      </CustomerContext.Provider>
    </div>
  );
};

Homepage.propTypes = {
  children: PropTypes.any,
};

export default Homepage;
