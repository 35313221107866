import { useMutation, useQueryClient } from 'react-query';
import { LocationsInternalClient } from '../nswag/atlas/AtlasClient';

export default function useDeleteDepartment() {
  const queryClient = useQueryClient();
  return useMutation(
    async (request: IDeleteDepartmentRequest) => {
      const client = new LocationsInternalClient();
      return await client.deleteDepartment(request.departmentId);
    },
    {
      onSuccess: (_, request) => {
        queryClient.invalidateQueries(['CUSTOMER', request.customerId]);
      },
    },
  );
}

interface IDeleteDepartmentRequest {
  departmentId: string;
  customerId: string;
}
