import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateLocationDialog from './CreateLocationDialog';
import useCreateApartment from 'requests/hooks/useCreateApartment';
import { CreateLocationRequest } from 'requests/nswag/atlas/AtlasClient';
import RenameLocationDialog from './RenameLocationDialog';
import DeleteLocationDialog from './DeleteLocationDialog';
import useDeleteDepartment from 'requests/hooks/useDeleteDepartment';
import { useCustomerContext } from 'requests/hooks/useCustomerContext';

type DepartmentDotMenuProps = {
  departmentId: string;
  departmentName: string;
};

function DepartmentDotMenu({ departmentId, departmentName }: DepartmentDotMenuProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const customer = useCustomerContext();
  const [openCreateApartment, setOpenCreateApartment] = useState(false);
  const [openRenameDepartment, setOpenRenameDepartment] = useState(false);
  const [openDeleteDepartment, setOpenDeleteDepartment] = useState(false);
  const createApartmentMutation = useCreateApartment();
  const deleteDepartmentMutation = useDeleteDepartment();

  function createApartment(name: string | undefined) {
    if (name !== undefined) {
      createApartmentMutation.mutate({
        createLocationRequest: new CreateLocationRequest({
          parentId: departmentId,
          name,
        }),
        customerId: customer.id,
      });
      setOpenCreateApartment(false);
    }
  }

  async function deleteDepartment() {
    await deleteDepartmentMutation.mutateAsync({
      departmentId,
      customerId: customer.id,
    });
    setOpenDeleteDepartment(false);
  }

  function onMenuClick(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }

  function onMenuClose() {
    setMenuAnchorEl(null);
  }

  const open = Boolean(menuAnchorEl);
  return (
    <>
      <IconButton style={{ padding: 0 }} onClick={onMenuClick} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={open}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            setOpenCreateApartment(true);
            onMenuClose();
          }}
        >
          <Typography>Create apartment</Typography>
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            setOpenRenameDepartment(true);
            onMenuClose();
          }}
        >
          <Typography>Rename department</Typography>
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            setOpenDeleteDepartment(true);
            onMenuClose();
          }}
        >
          <Typography>Delete department</Typography>
        </MenuItem>
      </Menu>
      <CreateLocationDialog
        title={'Create Apartment'}
        open={openCreateApartment}
        setOpen={setOpenCreateApartment}
        createLocation={createApartment}
        isLoading={createApartmentMutation.isLoading}
      />
      <RenameLocationDialog
        currentName={departmentName}
        setOpen={setOpenRenameDepartment}
        open={openRenameDepartment}
        id={departmentId}
      />
      <DeleteLocationDialog
        title={'Delete department'}
        open={openDeleteDepartment}
        setOpen={setOpenDeleteDepartment}
        deleteLocation={deleteDepartment}
        isLoading={deleteDepartmentMutation.isLoading}
      />
    </>
  );
}

export default DepartmentDotMenu;
