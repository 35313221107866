import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './Authentication/AuthProvider';
import Homepage from './Components/Homepage';
import { Router } from './Routing/Router';
import CustomerNotSelectedErrorView from './Components/CustomerNotSelectedErrorView';
import { SnackbarProvider } from 'notistack';
import SnackbarCloseButton from './Components/SnackbarCloseButton';

const queryClient = new QueryClient();

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            maxSnack={1}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
          >
            <Homepage>
              <CustomerNotSelectedErrorView>
                <Router />
              </CustomerNotSelectedErrorView>
            </Homepage>
          </SnackbarProvider>
        </QueryClientProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};
