import { ConfigurationResponse } from '../nswag/nexus/NexusClient';
import { useQuery, UseQueryResult } from 'react-query';
import { ConfigurationClient } from '../nswag/nexus/NexusClient';

async function getNexusConfigurationForCustomer(customerId: string) {
  const client = new ConfigurationClient();

  return client.getConfiguration(customerId);
}

export function useFetchNexusConfiguration(customerId: string): UseQueryResult<ConfigurationResponse> {
  return useQuery(['NEXUS_CONFIG', customerId], async () => {
    return await getNexusConfigurationForCustomer(customerId);
  });
}
