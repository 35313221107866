import { useMutation, useQueryClient } from 'react-query';
import { LocationsInternalClient } from '../nswag/atlas/AtlasClient';

export default function useDeleteDepartment() {
  const queryClient = useQueryClient();
  return useMutation(
    async (request: IDeleteOrganisationRequest) => {
      const client = new LocationsInternalClient();
      return await client.deleteOrganisation(request.organisationId);
    },
    {
      onSuccess: (_, request) => {
        queryClient.invalidateQueries(['CUSTOMER', request.customerId]);
      },
    },
  );
}

interface IDeleteOrganisationRequest {
  organisationId: string;
  customerId: string;
}
