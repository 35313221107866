import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LinearProgress } from '@mui/material';
import { useSnackbar } from 'notistack';

interface ICreateLoctionDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  createLocation: (name: string | undefined) => void;
  isLoading: boolean;
}

export default function CreateLocationDialog(props: ICreateLoctionDialogProps) {
  const { open, setOpen, title, createLocation, isLoading } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Dialog open={open} onClick={(event) => event.stopPropagation()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <TextField inputRef={inputRef} autoFocus margin="dense" id="name" label="Name" fullWidth />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            setOpen(false);
            event.stopPropagation();
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={(event) => {
            try {
              event.stopPropagation();
              createLocation(inputRef.current?.value);
            } catch (error) {
              setOpen(false);
              enqueueSnackbar((error as Error).message, {
                variant: 'error',
              });
            }
          }}
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
