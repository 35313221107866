import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useCustomerContext } from 'requests/hooks/useCustomerContext';
import OrganisationSelector from 'Components/OrganisationSelector';
import StandardTasksSource from './StandardTasksSource';
import StandardTasksTarget from './StandardTasksTarget';

const StandardTasksView: React.FC = () => {
  const customer = useCustomerContext();
  const [sourceOrganisation, setSourceDestination] = useState('');
  const [targetOrganisation, setTargetDestination] = useState('');

  return (
    <Box m={2}>
      <Typography variant="h5" component="span">
        Standard Tasks - {customer.name}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <OrganisationSelector
            customer={customer}
            setSelectedOrganisationId={setSourceDestination}
            title={'Source organisation'}
          />
        </Grid>
        <Grid item xs={6}>
          <OrganisationSelector
            customer={customer}
            setSelectedOrganisationId={setTargetDestination}
            title={'Target organisation'}
          />
        </Grid>
        <Grid item xs={6}>
          <StandardTasksSource sourceOrganisationId={sourceOrganisation} targetOrganisationId={targetOrganisation} />
        </Grid>
        <Grid item xs={6}>
          <StandardTasksTarget organisationId={targetOrganisation} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default StandardTasksView;
